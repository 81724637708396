<template>
  <computed-document-ref
    v-if="value"
    :id="value.id"
    :document-type="value.type"
    :extra="extra"
    :options="computedOptions"
    :account-id="accountId"
  />
</template>

<script>
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  components: {
    ComputedDocumentRef: () => import('@/components/documents-ref/computed-document-ref')
  },
  computed: {
    computedOptions () {
      return Object.assign({}, this.column.options, { hideNavigation: true, isForSelection: false })
    }
  },
  props: {
    value: {
      type: Object
    },
    extra: Object,
    column: Column,
    accountId: String
  }
}
</script>
